import React, { useLayoutEffect, useRef, useState } from 'react';

import cls from '../helpers/cls';

import Linear from './Linear';

const Collapse = ({
  className,
  children,
  Component = Linear,
  collapsedSize = '0px',
  in: extIsOpen,
  timeout = 300,
  ...rest
}) => {
  const isOpen = Boolean(extIsOpen);

  const [{
    hasChangedFromInitialIsOpen,
    initialValueOfIsOpen,
  }, setInitialState] = useState({
    hasChangedFromInitialIsOpen: false,
    initialValueOfIsOpen: isOpen,
  });

  useLayoutEffect(() => {
    if (!hasChangedFromInitialIsOpen && isOpen !== initialValueOfIsOpen) {
      requestAnimationFrame(() => {
        setInitialState({ hasChangedFromInitialIsOpen: true });
      });
    }
  }, [hasChangedFromInitialIsOpen, initialValueOfIsOpen, isOpen]);

  const ref = useRef(null);

  useLayoutEffect(() => {
    const element = ref.current;
    if (!element) return undefined;
    const state = { timer: undefined };
    const delay = hasChangedFromInitialIsOpen ? timeout : 0;
    if (isOpen) {
      element.style.display = null;
      element.style.height = `${element.scrollHeight}px`;
      state.timer = setTimeout(() => {
        element.style.overflow = null;
        element.style.height = 'auto';
      }, delay);
    } else {
      element.style.overflow = 'hidden';
      element.style.height = `${element.scrollHeight}px`;
      // set to scrollHeight first because height: auto -> 0px will not animate
      requestAnimationFrame(() => {
        element.style.height = collapsedSize;
      });

      state.timer = setTimeout(() => {
        element.style.display = 'none';
      }, delay);
    }
    return () => clearTimeout(state.timer);
  }, [collapsedSize, hasChangedFromInitialIsOpen, isOpen, timeout]);

  return (
    <Component
      {...rest}
      className={cls('Collapse', {}, className)}
      orientation="vertical"
      ref={ref}
      style={{
        ...rest.style,
        transition: `height ${(timeout / 1000).toFixed(2)}s linear`,
      }}
      width="100pr"
    >
      {children}
    </Component>
  );
};

export default Collapse;
