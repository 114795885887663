import { lazy } from 'react';
import {
  groupBy,
  flatMap,
  map,
  pickBy,
  uniq,
} from 'lodash';

import chain from 'lib-frontend-shared/src/helpers/chain';
import { getStates } from '../../store';
import { PageNotFound } from './shared';
import { permissionGroups } from '../../permissions';

const CreateShipment = lazy(() => import(
  /* webpackChunkName: "[CreateShipment]" */
  '../shipment/create/CreateShipment'
));

const Rule = lazy(() => import(
  /* webpackChunkName: "[Rule]" */
  '../rule/Rule'
));

const ServiceLevel = lazy(() => import(
  /* webpackChunkName: "[EditServiceLevelSetting]" */
  '../service-levels-settings/EditServiceLevelSetting'
));

const baseNotificationEntityTypes = {
  'reverse-shipment': {
    label: 'Reverse Shipment',
  },
  shipment: {
    label: 'Forward Shipment',
  },
};

const returnRequestNotificationEntityTypes = {
  'return-request': {
    label: 'Return Request',
  },
};

const notificationEntityTypes = {
  ...baseNotificationEntityTypes,
  ...returnRequestNotificationEntityTypes,
};

const routes = [
  {
    routeId: 'home',
    permissionGroup: permissionGroups.REPORT,
    highlightSideMenuLinkId: 'home',
    title: 'Home',
    hideTitleHeader: true,
    path: '/',
    Component: lazy(() => import(
      /* webpackChunkName: "[ReportsDashboard]" */
      '../dashboard/Dashboard'
    )),
    MobileComponent: lazy(() => import(
      /* webpackChunkName: "[ReportsDashboard]" */
      '../dashboard/Dashboard'
    )),
  },
  {
    routeId: 'catalog-items',
    permissionGroup: permissionGroups.CATALOG,
    highlightSideMenuLinkId: 'catalog-items',
    title: 'Items',
    hideTitleHeader: true,
    breadcrumbs: ['settings'],
    path: '/settings/catalog/items',
    Component: lazy(() => import(
      /* webpackChunkName: "[catalogItems]" */
      '../catalog/Items'
    )),
  },
  {
    routeId: 'control-tower',
    permissionGroup: permissionGroups.REPORT,
    highlightSideMenuLinkId: 'control-tower',
    title: 'Control Tower',
    description: 'The control tower monitors all active shipments that are up to 90 days old.',
    hideTitleHeader: true,
    path: '/control-tower',
    Component: lazy(() => import(
      /* webpackChunkName: "[controlTower]" */
      '../control-tower/ControlTower'
    )),
  },
  {
    routeId: 'create-shipment',
    highlightSideMenuLinkId: 'shipments',
    permissionGroup: permissionGroups.SHIPMENT,
    title: 'Create Forward Shipment',
    breadcrumbs: ['shipments'],
    gradientBackground: true,
    path: '/:entitySlug(shipments)/:actionMode(create)/:shipmentId?',
    Component: CreateShipment,
  },
  {
    routeId: 'edit-shipment',
    highlightSideMenuLinkId: 'shipments',
    permissionGroup: permissionGroups.SHIPMENT,
    title: 'Edit Forward Shipment',
    breadcrumbs: ['shipments', 'shipment'],
    gradientBackground: true,
    path: '/:entitySlug(shipments)/:actionMode(edit)/:shipmentId',
    Component: CreateShipment,
  },
  {
    routeId: 'clone-shipment',
    highlightSideMenuLinkId: 'shipments',
    permissionGroup: permissionGroups.SHIPMENT,
    gradientBackground: true,
    title: 'Clone Forward Shipment',
    breadcrumbs: ['shipments', 'shipment'],
    path: '/:entitySlug(shipments)/:actionMode(clone)/:shipmentId',
    Component: CreateShipment,
  },
  {
    routeId: 'confirm-shipment',
    highlightSideMenuLinkId: 'shipments',
    permissionGroup: permissionGroups.SHIPMENT,
    gradientBackground: true,
    title: 'Confirm Forward Shipment',
    breadcrumbs: ['shipments', 'shipment'],
    path: '/:entitySlug(shipments)/:actionMode(confirm)/:shipmentId',
    Component: CreateShipment,
  },
  {
    routeId: 'shipment',
    permissionGroup: permissionGroups.SHIPMENT,
    highlightSideMenuLinkId: 'shipments',
    title: 'Shipment Detail',
    breadcrumbs: ['shipments'],
    path: '/shipments/:shipmentId/:activeTab(summary|history|systemlog)?',
    gradientBackground: true,
    Component: lazy(() => import(
      /* webpackChunkName: "[Shipment]" */
      '../shipment/Shipment'
    )),
    MobileComponent: lazy(() => import(
      /* webpackChunkName: "[ShipmentMobile]" */
      '../shipment/mobile-view/ShipmentMobile'
    )),
  },
  {
    routeId: 'shipments',
    permissionGroup: permissionGroups.SHIPMENT,
    highlightSideMenuLinkId: 'shipments',
    title: 'Shipments',
    path: '/shipments',
    description: 'List of all shipments.',
    breadcrumbs: [],
    Component: lazy(() => import(
      /* webpackChunkName: "[ShipmentList]" */
      '../shipment-list-shared/ShipmentList'
    )),
    MobileComponent: lazy(() => import(
      /* webpackChunkName: "[ShipmentListMobile]" */
      '../shipment-list-shared/mobile-view/ShipmentListMobile'
    )),
  },


  // reverse-shipments
  {
    routeId: 'create-reverse-shipment',
    permissionGroup: permissionGroups.SHIPMENT,
    highlightSideMenuLinkId: 'shipments',
    gradientBackground: true,
    breadcrumbs: ['reverse-shipments'],
    title: 'Create Reverse Shipment',
    path: '/:entitySlug(reverse-shipments)/:actionMode(create)/:shipmentId?',
    Component: CreateShipment,
  },
  {
    routeId: 'edit-reverse-shipment',
    permissionGroup: permissionGroups.SHIPMENT,
    highlightSideMenuLinkId: 'shipments',
    breadcrumbs: ['reverse-shipments', 'reverse-shipment'],
    title: 'Edit Reverse Shipment',
    gradientBackground: true,
    path: '/:entitySlug(reverse-shipments)/:actionMode(edit)/:shipmentId',
    Component: CreateShipment,
  },
  {
    routeId: 'clone-reverse-shipment',
    highlightSideMenuLinkId: 'shipments',
    gradientBackground: true,
    breadcrumbs: ['reverse-shipments', 'reverse-shipment'],
    permissionGroup: permissionGroups.SHIPMENT,
    title: 'Clone Reverse Shipment',
    path: '/:entitySlug(reverse-shipments)/:actionMode(clone)/:shipmentId',
    Component: CreateShipment,
  },
  {
    routeId: 'confirm-reverse-shipment',
    highlightSideMenuLinkId: 'shipments',
    gradientBackground: true,
    permissionGroup: permissionGroups.SHIPMENT,
    breadcrumbs: ['reverse-shipments', 'reverse-shipment'],
    title: 'Confirm Reverse Shipment',
    path: '/:entitySlug(reverse-shipments)/:actionMode(confirm)/:shipmentId',
    Component: CreateShipment,
  },
  {
    routeId: 'reverse-shipment',
    permissionGroup: permissionGroups.SHIPMENT,
    highlightSideMenuLinkId: 'shipments',
    title: 'Shipment Detail',
    breadcrumbs: ['reverse-shipments'],
    path: '/reverse-shipments/:shipmentId/:activeTab(summary|history|systemlog)?',
    gradientBackground: true,
    Component: lazy(() => import(
      /* webpackChunkName: "[Shipment]" */
      '../shipment/Shipment'
    )),
    MobileComponent: lazy(() => import(
      /* webpackChunkName: "[ShipmentMobile]" */
      '../shipment/mobile-view/ShipmentMobile'
    )),
  },
  {
    routeId: 'reverse-shipments',
    permissionGroup: permissionGroups.SHIPMENT,
    highlightSideMenuLinkId: 'shipments',
    title: 'Shipments',
    path: '/reverse-shipments',
    breadcrumbs: [],
    Component: lazy(() => import(
      /* webpackChunkName: "[ShipmentList]" */
      '../shipment-list-shared/ShipmentList'
    )),
    MobileComponent: lazy(() => import(
      /* webpackChunkName: "[ShipmentListMobile]" */
      '../shipment-list-shared/mobile-view/ShipmentListMobile'
    )),
  },

  // ORDERS

  {
    routeId: 'orders',
    permissionGroup: permissionGroups.ORDER,
    highlightSideMenuLinkId: 'orders',
    title: 'Orders',
    path: '/orders',
    description: 'List of Customer Orders.',
    breadcrumbs: [],
    Component: lazy(() => import(
      /* webpackChunkName: "[OrderList]" */
      '../orders/order-list/OrderList'
    )),
  },
  {
    routeId: 'order',
    permissionGroup: permissionGroups.ORDER,
    highlightSideMenuLinkId: 'orders',
    title: 'Order Detail',
    breadcrumbs: ['orders'],
    path: '/orders/:orderId/:activeTab(summary|systemlog)?',
    gradientBackground: true,
    Component: lazy(() => import(
      /* webpackChunkName: "[Order]" */
      '../orders/order/Order'
    )),
  },

  // MANIFESTS
  {
    routeId: 'manifest-list',
    permissionGroup: permissionGroups.MANIFEST,
    highlightSideMenuLinkId: 'manifests',
    title: 'Manifests',
    description: 'Manifests allow preparing, declaring and clearing packing lists.',
    path: '/manifests',
    breadcrumbs: [],
    Component: lazy(() => import(
      /* webpackChunkName: "[ManifestList]" */
      '../manifest-list/ManifestList'
    )),
  },
  {
    routeId: 'manifest-create',
    permissionGroup: permissionGroups.MANIFEST,
    highlightSideMenuLinkId: 'manifests',
    title: 'Create Manifest',
    gradientBackground: true,
    path: '/manifests/create',
    breadcrumbs: ['manifest-list'],
    Component: lazy(() => import(
      /* webpackChunkName: "[Manifest]" */
      '../manifest/Manifest'
    )),
  },
  {
    routeId: 'manifest',
    permissionGroup: permissionGroups.MANIFEST,
    highlightSideMenuLinkId: 'manifests',
    title: 'Manifest',
    path: '/manifests/:manifestId',
    gradientBackground: true,
    breadcrumbs: ['manifest-list'],
    Component: lazy(() => import(
      /* webpackChunkName: "[Manifest]" */
      '../manifest/Manifest'
    )),
  },

  // return requests
  {
    routeId: 'return-request-list',
    permissionGroup: permissionGroups.RETURN_REQUEST,
    highlightSideMenuLinkId: 'return-request-list',
    title: 'Return Requests',
    description: 'View and manage all your returns.',
    path: '/return-request',
    breadcrumbs: [],
    Component: lazy(() => import(
      /* webpackChunkName: "[ReturnRequestList]" */
      '../return-request-list/ReturnRequestList'
    )),
  },
  {
    routeId: 'return-request-settings',
    permissionGroup: permissionGroups.RETURN_REQUEST_SETTING,
    highlightSideMenuLinkId: 'return-request-settings',
    title: 'Return Settings',
    path: '/settings/return',
    breadcrumbs: ['settings'],
    Component: lazy(() => import(
      /* webpackChunkName: "[ReturnRequestSettings]" */
      '../return-request-settings/ReturnRequestSettings'
    )),
  },
  {
    routeId: 'edit-return-request',
    permissionGroup: permissionGroups.RETURN_REQUEST,
    title: 'Edit Return Request',
    highlightSideMenuLinkId: 'return-request-list',
    path: '/return-request/edit/:returnRequestId',
    gradientBackground: true,
    breadcrumbs: ['return-request-list'],
    Component: lazy(() => import(
      /* webpackChunkName: "[EditReturnRequest]" */
      '../edit-return-request/EditReturnRequest'
    )),
  },
  {
    routeId: 'return-request-detail',
    permissionGroup: permissionGroups.RETURN_REQUEST,
    highlightSideMenuLinkId: 'return-request-list',
    title: 'Return Request Detail',
    breadcrumbs: ['return-request-list'],
    path: '/return-request/:returnRequestId/:activeTab(summary|refunds|notes)?',
    Component: lazy(() => import(
      /* webpackChunkName: "[ReturnRequestDetail]" */
      '../return-request-detail/ReturnRequestDetail'
    )),
  },

  // packing-templates
  {
    routeId: 'packing-templates',
    permissionGroup: permissionGroups.PACKING_TEMPLATE,
    highlightSideMenuLinkId: 'packing-templates',
    title: 'Packing Templates',
    breadcrumbs: ['settings'],
    description: 'Manage your shipping packages to be used when packing orders.',
    path: '/settings/packing-templates',
    Component: lazy(() => import(
      /* webpackChunkName: "[PackingTemplateList]" */
      '../packing-template/PackingTemplateList'
    )),
  },

  // reports
  {
    routeId: 'reports',
    permissionGroup: permissionGroups.REPORT,
    highlightSideMenuLinkId: 'reports',
    title: 'Reports',
    path: '/reports',
    breadcrumbs: [],
    description: 'Analyze performance and historical data. Mark your favorite reports so that they always show on top.',
    Component: lazy(() => import(
      /* webpackChunkName: "[Reports]" */
      '../reports/Reports'
    )),
  },
  {
    routeId: 'reports-service-levels',
    permissionGroup: permissionGroups.REPORT,
    highlightSideMenuLinkId: 'reports-service-levels',
    title: 'Service Level Performance',
    path: '/reports-service-levels',
    breadcrumbs: [],
    description: 'Analyze service level and customer promise performance.',
    Component: lazy(() => import(
      /* webpackChunkName: "[ReportsServiceLevels]" */
      '../reports/service-levels/ServiceLevels'
    )),
  },
  {
    routeId: 'reports-regional-analysis',
    permissionGroup: permissionGroups.REGIONAL_ANALYSIS_REPORT,
    highlightSideMenuLinkId: 'reports-regional-analysis',
    title: 'Regional Analysis',
    description: 'Explore last mile deliver performance by geographical region.',
    path: '/reports-regional-analysis',
    breadcrumbs: [],
    Component: lazy(() => import(
      /* webpackChunkName: "[ReportsRegionalAnalysis]" */
      '../reports/regional-analysis/RegionalAnalysis'
    )),
  },
  {
    routeId: 'reports-carrier-performance',
    permissionGroup: permissionGroups.REPORT,
    highlightSideMenuLinkId: 'reports-carrier-performance',
    title: 'Carrier Performance',
    description: 'Compare carriers across different regions and services.',
    path: '/reports-carrier-performance',
    breadcrumbs: [],
    Component: lazy(() => import(
      /* webpackChunkName: "[ReportsCarrierPerf]" */
      '../reports/carrier-perf/CarrierPerf'
    )),
  },
  {
    routeId: 'reports-notifications',
    permissionGroup: permissionGroups.NOTIFICATION_REPORT,
    highlightSideMenuLinkId: 'reports-notifications',
    title: 'Customer Notification',
    path: '/reports-notifications',
    description: 'Review sent notifications performance and compare across channels.',
    breadcrumbs: [],
    Component: lazy(() => import(
      /* webpackChunkName: "[ReportsNotifications]" */
      '../reports/notifications-report/NotificationsReport'
    )),
  },
  {
    routeId: 'reports-feedbacks',
    permissionGroup: permissionGroups.FEEDBACK_REPORT,
    highlightSideMenuLinkId: 'reports-feedbacks',
    title: 'Customer Feedback',
    path: '/reports-feedbacks',
    description: 'View all customer feedback records for improvement purposes.',
    breadcrumbs: [],
    Component: lazy(() => import(
      /* webpackChunkName: "[ReportsFeedbacks]" */
      '../reports/feedback/FeedbacksReport'
    )),
  },
  {
    routeId: 'reports-order-tracking',
    permissionGroup: permissionGroups.REPORT,
    highlightSideMenuLinkId: 'reports-order-tracking',
    title: 'Order Tracking',
    path: '/reports-order-tracking',
    description: 'Analyze customer behaviour and tracking page performance.',
    breadcrumbs: [],
    Component: lazy(() => import(
      /* webpackChunkName: "[ReportsCXPages]" */
      '../reports/cx-pages/CXPageReport'
    )),
  },
  {
    routeId: 'reports-shipment-history',
    permissionGroup: permissionGroups.REPORT,
    highlightSideMenuLinkId: 'reports-shipment-history',
    title: 'Shipment History',
    path: '/reports-shipment-history',
    description: 'Analyze shipping trends and break them down into operational insights.',
    breadcrumbs: [],
    Component: lazy(() => import(
      /* webpackChunkName: "[ReportsShipmentHistory]" */
      '../reports/shipment-history/ShipmentHistory'
    )),
  },
  {
    routeId: 'reports-settlement',
    permissionGroup: permissionGroups.SETTLEMENT_REPORT,
    highlightSideMenuLinkId: 'reports-settlement',
    title: 'Settlement',
    path: '/reports-settlement',
    description: 'Analyze shipping costs and match them against carrier invoices.',
    breadcrumbs: [],
    Component: lazy(() => import(
      /* webpackChunkName: "[ReportsSettlement]" */
      '../reports/settlement/Settlement'
    )),
  }, {
    routeId: 'reports-error-history',
    permissionGroup: permissionGroups.REPORT,
    highlightSideMenuLinkId: 'reports-error-history',
    title: 'Error History',
    description: 'Review error trends by source, type, code and date.',
    path: '/reports-error-history',
    breadcrumbs: [],
    Component: lazy(() => import(
      /* webpackChunkName: "[ReportsErrorHistory]" */
      '../reports/error-history/ErrorHistory'
    )),
  },
  {
    routeId: 'reports-returns-history',
    permissionGroup: permissionGroups.REPORT,
    highlightSideMenuLinkId: 'reports-returns-history',
    title: 'Returns Report',
    description: 'Analyze and review your customer returns.',
    path: '/reports-returns-history',
    breadcrumbs: [],
    Component: lazy(() => import(
      /* webpackChunkName: "[ReportsReturnsHistory]" */
      '../reports/returns-history/ReturnsHistory'
    )),
  },
  {
    routeId: 'reports-transit-time',
    permissionGroup: permissionGroups.REPORT,
    highlightSideMenuLinkId: 'reports-transit-time',
    title: 'Transit Time',
    path: '/reports-transit-time',
    description: 'Analyze delivery transit time by time period, carrier and delivery type.',
    breadcrumbs: [],
    Component: lazy(() => import(
      /* webpackChunkName: "[ReportsTransitTime]" */
      '../reports/transit-time/DeliveryTime'
    )),
  },
  {
    routeId: 'reports-ageing-shipments',
    permissionGroup: permissionGroups.REPORT,
    highlightSideMenuLinkId: 'reports-ageing-shipments',
    title: 'Shipment Ageing',
    description: 'Analyze open shipment volume by age.',
    path: '/reports-ageing-shipments',
    breadcrumbs: [],
    Component: lazy(() => import(
      /* webpackChunkName: "[ReportsAgeing]" */
      '../reports/ageing/Ageing'
    )),
  },

  // rule sets
  {
    routeId: 'rule-sets',
    permissionGroup: permissionGroups.ROUTING_RULE_SET,
    highlightSideMenuLinkId: 'rule-sets',
    title: 'Forward Shipping Rule Sets',
    breadcrumbs: ['settings'],
    description: `Build groups of rules by country or by merchant into different rule sets.
          Rules for a merchant and pickup country cannot exist in more than one active rule set.`,
    path: '/settings/rule-sets',
    Component: lazy(() => import(
      /* webpackChunkName: "[RuleSetList]" */
      '../rule-sets/RuleSetList'
    )),
  },
  {
    routeId: 'reverse-rule-sets',
    permissionGroup: permissionGroups.ROUTING_RULE_SET,
    highlightSideMenuLinkId: 'reverse-rule-sets',
    title: 'Reverse Shipping Rule Sets',
    breadcrumbs: ['settings'],
    description: `Build groups of rules by country or by merchant into different rule sets.
          Rules for a merchant and pickup country cannot exist in more than one active rule set.`,
    path: '/settings/reverse-rule-sets',
    Component: lazy(() => import(
      /* webpackChunkName: "[RuleSetList]" */
      '../rule-sets/RuleSetList'
    )),
  },

  // rules
  {
    routeId: 'rules',
    permissionGroup: permissionGroups.ROUTING_RULE,
    highlightSideMenuLinkId: 'rule-sets',
    title: 'Forward Shipping Rules',
    description: `Rules allow to setup automatic assignment of shipments to 
          your carrier accounts based on shipment attributes. The rules are used at 
          the time of shipment booking when Auto Select is used or a carrier account is 
          not predefined. Rules will be applied by order of sequence.`,
    path: '/settings/rule-sets/:ruleSetId/rules',
    breadcrumbs: ['settings', 'rule-sets'],
    Component: lazy(() => import(
      /* webpackChunkName: "[RuleList]" */
      '../rule-list/RuleList'
    )),
  },
  {
    routeId: 'rule-create',
    permissionGroup: permissionGroups.ROUTING_RULE,
    highlightSideMenuLinkId: 'rule-sets',
    title: 'Rule',
    path: '/settings/rule-sets/:ruleSetId/rules/:actionType(create)',
    breadcrumbs: ['settings', 'rule-sets', 'rules'],
    gradientBackground: true,
    Component: Rule,
  },
  {
    routeId: 'rule-edit',
    permissionGroup: permissionGroups.ROUTING_RULE,
    highlightSideMenuLinkId: 'rule-sets',
    title: 'Rule',
    path: '/settings/rule-sets/:ruleSetId/rules/:actionType(edit)/:ruleId/:activeTab(settings|statistics)?',
    breadcrumbs: ['settings', 'rule-sets', 'rules'],
    gradientBackground: true,
    Component: Rule,
  },


  // reverse-shipment-rules
  {
    routeId: 'reverse-shipment-rules',
    permissionGroup: permissionGroups.ROUTING_RULE,
    highlightSideMenuLinkId: 'reverse-rule-sets',
    title: 'Reverse Shipping Rules',
    description: `Rules allow to setup automatic assignment of shipments to 
          your carrier accounts based on shipment attributes. The rules are used at 
          the time of shipment booking when Auto Select is used or a carrier account is 
          not predefined. Rules will be applied by order of sequence.`,
    path: '/settings/reverse-rule-sets/:ruleSetId/rules',
    breadcrumbs: ['settings', 'reverse-rule-sets'],
    Component: lazy(() => import(
      /* webpackChunkName: "[ReverseShipmentRuleList]" */
      '../reverse-shipment-rule-list/ReverseShipmentRuleList'
    )),
  },
  {
    routeId: 'reverse-shipment-rule-create',
    permissionGroup: permissionGroups.ROUTING_RULE,
    highlightSideMenuLinkId: 'reverse-rule-sets',
    title: 'Rule',
    breadcrumbs: ['settings', 'reverse-rule-sets', 'reverse-shipment-rules'],
    path: '/settings/reverse-rule-sets/:ruleSetId/rules/:actionType(create)',
    Component: Rule,
  },
  {
    routeId: 'reverse-shipment-rule-edit',
    permissionGroup: permissionGroups.ROUTING_RULE,
    highlightSideMenuLinkId: 'reverse-rule-sets',
    title: 'Rule',
    breadcrumbs: ['settings', 'reverse-rule-sets', 'reverse-shipment-rules'],
    path: '/settings/reverse-rule-sets/:ruleSetId/rules/:actionType(edit)/:ruleId/:activeTab(settings|statistics)?',
    Component: Rule,
  },

  // servie level sets
  {
    routeId: 'service-level-sets',
    permissionGroup: permissionGroups.SERVICE_LEVEL_SET,
    highlightSideMenuLinkId: 'service-level-sets',
    title: 'Service Level Sets',
    breadcrumbs: ['settings'],
    description: `Build groups of service levels by country or by merchant into different service level sets.
          Service Levels for a merchant and pickup country cannot exist in more than one active service level set.`,
    path: '/settings/service-level-sets/:entityType(forward|reverse)?',
    Component: lazy(() => import(
      /* webpackChunkName: "[ServiceLevelSetList]" */
      '../service-level-sets/ServiceLevelSetList'
    )),
  },

  // service-levels
  {
    routeId: 'service-levels',
    permissionGroup: permissionGroups.SERVICE_LEVEL,
    highlightSideMenuLinkId: 'service-level-sets',
    title: 'Service Levels',
    breadcrumbs: ['settings', 'service-level-sets'],
    description: `Service level rules allow for the automatic calculation of targets for processing time,
          collection time, delivery time and the customer promise date for each individual shipment based on different conditions. 
          Using this feature enables reviwing service level metrics and identifying shipments at risk and late shipments.`,
    path: '/settings/service-level-sets/:ruleSetId/service-levels/:entityType(forward|reverse)?/:subTab(fulfillment|shipping|delivery|promised)?',
    Component: lazy(() => import(
      /* webpackChunkName: "[ServiceLevelSettings]" */
      '../service-levels-settings/ServiceLevelSettings'
    )),
  },
  {
    routeId: 'service-level-create',
    permissionGroup: permissionGroups.SERVICE_LEVEL,
    highlightSideMenuLinkId: 'service-level-sets',
    title: 'Service Level',
    breadcrumbs: ['settings', 'service-level-sets', 'service-levels'],
    path: '/settings/service-level-sets/:ruleSetId/service-levels/:entityType(forward|reverse)/:subTab(fulfillment|shipping|delivery|promised)/:actionType(create)',
    Component: ServiceLevel,
    gradientBackground: true,
  },
  {
    routeId: 'service-level-edit',
    permissionGroup: permissionGroups.SERVICE_LEVEL,
    highlightSideMenuLinkId: 'service-level-sets',
    title: 'Service Level',
    breadcrumbs: ['settings', 'service-level-sets', 'service-levels'],
    path: '/settings/service-level-sets/:ruleSetId/service-levels/:entityType(forward|reverse)/:subTab(fulfillment|shipping|delivery|promised)/:actionType(edit)/:configId',
    Component: ServiceLevel,
    gradientBackground: true,
  },

  // users
  {
    routeId: 'users',
    permissionGroup: permissionGroups.USER,
    highlightSideMenuLinkId: 'users',
    title: 'Users',
    path: '/settings/users',
    description: 'Manage and invite new users.',
    breadcrumbs: ['settings'],
    Component: lazy(() => import(
      /* webpackChunkName: "[UserList]" */
      '../user-list/UserList'
    )),
  },

  // domian list
  {
    routeId: 'custom-domains',
    permissionGroup: permissionGroups.USER,
    highlightSideMenuLinkId: 'custom-domains',
    breadcrumbs: ['settings'],
    title: 'Custom Domains',
    description: `Setup custom domains to define the url for tracking pages, 
          customer feedbackl loop pages and other CX add-ons.`,
    path: '/settings/custom-domains',
    Component: lazy(() => import(
      /* webpackChunkName: "[DomainList]" */
      '../custom-domains/DomainList'
    )),
  },

  // notification-settings
  {
    routeId: 'notification',
    permissionGroup: permissionGroups.NOTIFICATION_SETTING,
    title: 'Notifications',
    breadcrumbs: ['settings'],
    accountBadge: () => getStates().global.canShowAccountNotificationSettings,
    path: '/settings/notifications/:channel(email|sms|whatsapp)?/:activeTab(settings|triggers|templates)?',
    Component: lazy(() => import(
      /* webpackChunkName: "[Notifications]" */
      '../notifications/Notifications'
    )),
  },
  ...flatMap(['SMS', 'Email', 'WhatsApp'], (channelName) => {
    const channel = channelName.toLowerCase();
    return [
      {
        routeId: `pinpoint-notifications-${channel}-template-setting`,
        permissionGroup: permissionGroups.NOTIFICATION_SETTING,
        highlightSideMenuLinkId: 'pinpoint-notifications',
        title: `Pinpoint Location ${channelName} Templates`,
        accountBadge: () => getStates().global.canShowAccountNotificationSettings,
        path: `/settings/notifications/:entityType(pinpoint-location)/:channel(${channel})/:status(pinpoint_location)/:reason(_ANY)/:language/:actionType`,
        Component: lazy(() => import(
          /* webpackChunkName: "[NotificationTemplateSetting]" */
          '../entity-notifications-settings/Template'
        )),
      },
      ...map(
        Object.entries(notificationEntityTypes),
        ([entityType, { label }]) => ({
          routeId: `${entityType}-notifications-${channel}-template-setting`,
          permissionGroup: permissionGroups.NOTIFICATION_SETTING,
          highlightSideMenuLinkId: `${entityType}-notifications`,
          title: `${label} ${channelName} Templates`,
          accountBadge: () => getStates().global.canShowAccountNotificationSettings,
          path: `/settings/notifications/:entityType(${entityType})/:channel(${channel})/:status/:reason/:language/:actionType`,
          Component: lazy(() => import(
            /* webpackChunkName: "[NotificationTemplateSetting]" */
            '../entity-notifications-settings/Template'
          )),
        }),
      ),
    ];
  }),

  // carriers
  {
    routeId: 'click-n-ship',
    permissionGroup: permissionGroups.CLICK_N_SHIP,
    highlightSideMenuLinkId: 'click-n-ship',
    title: 'Click\'n\'Ship',
    description: 'Select and manage the Click’n’Ship services your want to use from the list of available services.',
    path: '/settings/click-n-ship',
    breadcrumbs: ['settings'],
    gradientBackground: true,
    Component: lazy(() => import(
      /* webpackChunkName: "[ClicknShip]" */
      '../click-n-ship/ClicknShip'
    )),
  },
  {
    routeId: 'carriers',
    permissionGroup: permissionGroups.CARRIER_ACCOUNT,
    highlightSideMenuLinkId: 'carriers',
    title: 'Carrier Accounts',
    breadcrumbs: ['settings'],
    description: 'Manage your carrier accounts with your preferred carriers.',
    path: '/settings/carriers',
    Component: lazy(() => import(
      /* webpackChunkName: "[CarrierList]" */
      '../carrier-settings/CarrierList'
    )),
  },
  {
    routeId: 'carrier',
    permissionGroup: permissionGroups.CARRIER_ACCOUNT,
    highlightSideMenuLinkId: 'carriers',
    title: 'Carrier Account Setup',
    breadcrumbs: ['settings', 'carriers'],
    path: '/settings/carriers/:actionType?/:carrierIdOrName?/:activeTab?',
    Component: lazy(() => import(
      /* webpackChunkName: "[Carrier]" */
      '../carrier-settings/Carrier'
    )),
  },
  {
    routeId: 'carrier-capacity',
    permissionGroup: permissionGroups.CARRIER_CAPACITY,
    highlightSideMenuLinkId: 'carrier-capacity',
    title: 'Carrier Capacity Profiles',
    description: `Carrier Capacity Profiles allow assigning a limited capacity to one 
          or more Carrier Accounts. When Carrier Capacity Profiles are assigned and active they 
          ensure that Automation Rules don't assign shipments to the specified Carrier Accounts 
          once the Capacity Threshhold is met. The Capacity Type defines what type of limit is 
          enforced by the Profile. For example maximum number of bookings per day versus maximum 
          number of open shipments allowed`,
    path: '/settings/carrier-capacity',
    breadcrumbs: ['settings'],
    Component: lazy(() => import(
      /* webpackChunkName: "[CarrierCapacityList]" */
      '../carrier-capacity/CapacityProfileList'
    )),
  },
  {
    routeId: 'capacity-profile-create',
    permissionGroup: permissionGroups.CARRIER_CAPACITY,
    highlightSideMenuLinkId: 'carrier-capacity',
    title: 'Create Daily Booking Capacity Profile ',
    path: '/settings/carrier-capacity/create',
    gradientBackground: true,
    breadcrumbs: ['settings', 'carrier-capacity'],
    Component: lazy(() => import(
      /* webpackChunkName: "[CarrierCapacity]" */
      '../carrier-capacity/CapacityProfile'
    )),
  },
  {
    routeId: 'capacity-profile-edit',
    permissionGroup: permissionGroups.CARRIER_CAPACITY,
    highlightSideMenuLinkId: 'carrier-capacity',
    title: 'Edit Daily Booking Capacity Profile ',
    breadcrumbs: ['settings', 'carrier-capacity'],
    path: '/settings/carrier-capacity/edit/:capacityProfileId',
    gradientBackground: true,
    Component: lazy(() => import(
      /* webpackChunkName: "[CarrierCapacity]" */
      '../carrier-capacity/CapacityProfile'
    )),
  },
  {
    routeId: 'capacity-profile-clone',
    permissionGroup: permissionGroups.CARRIER_CAPACITY,
    title: 'Duplicate Daily Booking Capacity Profile ',
    path: '/settings/carrier-capacity/clone/:capacityProfileId',
    gradientBackground: true,
    breadcrumbs: ['settings', 'carrier-capacity'],
    Component: lazy(() => import(
      /* webpackChunkName: "[CarrierCapacity]" */
      '../carrier-capacity/CapacityProfile'
    )),
  },
  {
    routeId: 'capacity-rule-create',
    permissionGroup: permissionGroups.CARRIER_CAPACITY,
    highlightSideMenuLinkId: 'carrier-capacity',
    breadcrumbs: ['settings', 'carrier-capacity', 'capacity-profile-edit'],
    title: 'Create Capacity Rule',
    path: '/settings/carrier-capacity/create/:capacityProfileId',
    gradientBackground: true,
    Component: lazy(() => import(
      /* webpackChunkName: "[CarrierCapacityRule]" */
      '../carrier-capacity/CapacityRule'
    )),
  },
  {
    routeId: 'capacity-rule-edit',
    permissionGroup: permissionGroups.CARRIER_CAPACITY,
    highlightSideMenuLinkId: 'carrier-capacity',
    breadcrumbs: ['settings', 'carrier-capacity', 'capacity-profile-edit'],
    title: 'Edit Capacity Rule',
    path: '/settings/carrier-capacity/edit/:capacityProfileId?/:capacityId',
    gradientBackground: true,
    Component: lazy(() => import(
      /* webpackChunkName: "[CarrierCapacityRule]" */
      '../carrier-capacity/CapacityRule'
    )),
  },
  {
    routeId: 'networks',
    permissionGroup: permissionGroups.CARRIER_NETWORK,
    highlightSideMenuLinkId: 'networks',
    title: 'Carrier Network Profiles',
    description: `A carrier account network represents the group of locations that can be served 
          by the carrier account. By selecting the countries and cities where this carrier account 
          can operate you can ensure that shipments that have a pickup or drop-off location outside 
          the network are not assigned to this carrier account by the rules.`,
    path: '/settings/networks',
    breadcrumbs: ['settings'],
    Component: lazy(() => import(
      /* webpackChunkName: "[CarrierNetworks]" */
      '../carrier-settings/network/CarrierNetwork'
    )),
  },
  {
    routeId: 'create-network',
    permissionGroup: permissionGroups.CARRIER_NETWORK,
    highlightSideMenuLinkId: 'networks',
    title: 'Create Network',
    breadcrumbs: ['settings', 'networks'],
    description: `Add specific locations to define the Carrier Network.
          Locations apply both to the Pickup and Dropoff locations
          supported by the carrier accounts selected.`,
    path: '/settings/networks/create',
    Component: lazy(() => import(
      /* webpackChunkName: "[CarrierNetworks]" */
      '../carrier-settings/network/CreateNetwork'
    )),
  },
  {
    routeId: 'edit-network',
    permissionGroup: permissionGroups.CARRIER_NETWORK,
    highlightSideMenuLinkId: 'networks',
    title: 'Edit Network',
    breadcrumbs: ['settings', 'networks'],
    description: `Add specific locations to define the Carrier Network.
          Locations apply both to the Pickup and Dropoff locations
          supported by the carrier accounts selected.`,
    path: '/settings/networks/edit/:networkId',
    Component: lazy(() => import(
      /* webpackChunkName: "[CarrierNetworks]" */
      '../carrier-settings/network/CreateNetwork'
    )),
  },
  {
    routeId: 'clone-network',
    permissionGroup: permissionGroups.CARRIER_NETWORK,
    highlightSideMenuLinkId: 'networks',
    title: 'Duplicate Network',
    breadcrumbs: ['settings', 'networks'],
    description: `Add specific locations to define the Carrier Network.
          Locations apply both to the Pickup and Dropoff locations
          supported by the carrier accounts selected.`,
    path: '/settings/networks/clone/:networkId',
    Component: lazy(() => import(
      /* webpackChunkName: "[CarrierNetworks]" */
      '../carrier-settings/network/CreateNetwork'
    )),
  },

  // CX Templates
  {
    routeId: 'default-settings',
    permissionGroup: permissionGroups.CX_TEMPLATES,
    highlightSideMenuLinkId: 'default-settings',
    title: 'CX Theme',
    breadcrumbs: ['settings'],
    description: 'Define your customer experience and branding for tracking pages, customer feedback forms and your returns portal.',
    path: '/settings/cx-theme',
    Component: lazy(() => import(
      /* webpackChunkName: "[CXTemplates]" */
      '../cx-templates/ThemeAndBranding'
    )),
  },
  {
    routeId: 'tracking-app',
    permissionGroup: permissionGroups.CX_TEMPLATES,
    featureId: 'premiumTracking',
    highlightSideMenuLinkId: 'tracking-app',
    title: 'Branded Tracking App',
    breadcrumbs: ['settings'],
    description: 'Customize your tracking page with relevant content and branded design.',
    path: '/settings/tracking-app',
    Component: lazy(() => import(
      /* webpackChunkName: "[CXTemplates]" */
      '../cx-templates/TrackingApp'
    )),
  },
  {
    routeId: 'feedback-app',
    permissionGroup: permissionGroups.CX_TEMPLATES,
    highlightSideMenuLinkId: 'feedback-app',
    title: 'Feedback Loop App',
    breadcrumbs: ['settings'],
    description: 'Customize your feedback page with relevant content and branded design.',
    path: '/settings/feedback-app',
    Component: lazy(() => import(
      /* webpackChunkName: "[CXTemplates]" */
      '../cx-templates/FeedbackApp'
    )),
  },
  {
    routeId: 'pinpoint-app',
    permissionGroup: permissionGroups.CX_TEMPLATES,
    highlightSideMenuLinkId: 'pinpoint-app',
    title: 'Pinpoint Location App',
    description: 'Customize your pinpoint page with relevant content and branded design.',
    breadcrumbs: ['settings'],
    path: '/settings/pinpoint-app',
    Component: lazy(() => import(
      /* webpackChunkName: "[CXTemplates]" */
      '../cx-templates/PinpointApp'
    )),
  },
  {
    routeId: 'returns-app',
    permissionGroup: permissionGroups.CX_TEMPLATES,
    highlightSideMenuLinkId: 'returns-app',
    title: 'Branded Returns Portal App',
    description: 'Customize your return page with relevant content and branded design.',
    breadcrumbs: ['settings'],
    path: '/settings/returns-app',
    Component: lazy(() => import(
      /* webpackChunkName: "[CXTemplates]" */
      '../cx-templates/ReturnsApp'
    )),
  },
  {
    routeId: 'element-translation',
    permissionGroup: permissionGroups.CX_TEMPLATES,
    highlightSideMenuLinkId: 'element-translation',
    title: 'Element Translation',
    description: `Certain element translations in Carriyo can be set by users. 
    Choose a language to set the appropriate translation copy of each available element.`,
    breadcrumbs: ['settings'],
    path: '/settings/element-translation',
    Component: lazy(() => import(
      /* webpackChunkName: "[CXTemplates]" */
      '../cx-templates/ElementTranslation'
    )),
  },

  // ShipmentCosting

  {
    routeId: 'shipment-costing',
    permissionGroup: permissionGroups.SHIPMENT_COSTING,
    highlightSideMenuLinkId: 'shipment-costing',
    title: 'Carrier Costing Profiles',
    description: 'Define cost profiles for each of your carrier accounts to calculate shipment costs upon booking.',
    path: '/settings/shipment-costing',
    breadcrumbs: ['settings'],
    Component: lazy(() => import(
      /* webpackChunkName: "[ShipmentCostingList]" */
      '../shipment-costing/CostingProfileList'
    )),
  },
  {
    routeId: 'shipment-costing-create',
    permissionGroup: permissionGroups.SHIPMENT_COSTING,
    highlightSideMenuLinkId: 'shipment-costing',
    title: 'Create Cost Profile',
    path: '/settings/shipment-costing/create',
    gradientBackground: true,
    breadcrumbs: ['settings', 'shipment-costing'],
    Component: lazy(() => import(
      /* webpackChunkName: "[ShipmentCosting]" */
      '../shipment-costing/CostingProfile'
    )),
  },
  {
    routeId: 'shipment-costing-edit',
    permissionGroup: permissionGroups.SHIPMENT_COSTING,
    highlightSideMenuLinkId: 'shipment-costing',
    title: 'Edit Cost Profile',
    path: '/settings/shipment-costing/edit/:costingProfileId',
    gradientBackground: true,
    breadcrumbs: ['settings', 'shipment-costing'],
    Component: lazy(() => import(
      /* webpackChunkName: "[ShipmentCosting]" */
      '../shipment-costing/CostingProfile'
    )),
  },
  {
    routeId: 'shipment-costing-clone',
    permissionGroup: permissionGroups.SHIPMENT_COSTING,
    title: 'Duplicate Cost Profile',
    path: '/settings/shipment-costing/clone/:costingProfileId',
    gradientBackground: true,
    breadcrumbs: ['settings', 'shipment-costing'],
    Component: lazy(() => import(
      /* webpackChunkName: "[ShipmentCosting]" */
      '../shipment-costing/CostingProfile'
    )),
  },
  {
    routeId: 'costing-rule-create',
    permissionGroup: permissionGroups.SHIPMENT_COSTING,
    highlightSideMenuLinkId: 'shipment-costing',
    title: 'Create Costing Rule',
    path: '/settings/shipment-costing/create/:costingProfileId',
    gradientBackground: true,
    breadcrumbs: ['settings', 'shipment-costing', 'shipment-costing-edit'],
    Component: lazy(() => import(
      /* webpackChunkName: "[ShipmentCostingRule]" */
      '../shipment-costing/CostingRule'
    )),
  },
  {
    routeId: 'costing-rule-edit',
    permissionGroup: permissionGroups.SHIPMENT_COSTING,
    highlightSideMenuLinkId: 'shipment-costing',
    title: 'Edit Costing Rule',
    path: '/settings/shipment-costing/edit/:costingProfileId/:ruleId',
    gradientBackground: true,
    breadcrumbs: ['settings', 'shipment-costing', 'shipment-costing-edit'],
    Component: lazy(() => import(
      /* webpackChunkName: "[ShipmentCostingRule]" */
      '../shipment-costing/CostingRule'
    )),
  },
  {
    routeId: 'costing-rule-clone',
    permissionGroup: permissionGroups.SHIPMENT_COSTING,
    highlightSideMenuLinkId: 'shipment-costing',
    title: 'Duplicate Costing Rule',
    path: '/settings/shipment-costing/clone/:costingProfileId/:ruleId',
    gradientBackground: true,
    breadcrumbs: ['settings', 'shipment-costing', 'shipment-costing-edit'],
    Component: lazy(() => import(
      /* webpackChunkName: "[ShipmentCostingRule]" */
      '../shipment-costing/CostingRule'
    )),
  },


  // Shipment attributes
  {
    routeId: 'shipment-attributes',
    permissionGroup: permissionGroups.CUSTOM_ATTRIBUTE,
    highlightSideMenuLinkId: 'shipment-attributes',
    title: 'Shipment Attributes',
    description: 'Create and manage Shipment Attributes to extend your shipment information.',
    path: '/settings/shipment-attributes/',
    breadcrumbs: ['settings'],
    Component: lazy(() => import(
      /* webpackChunkName: "[CustomProps]" */
      '../shipment-attributes/CustomProps'
    )),
  },

  // reason codes
  {
    routeId: 'reason-codes',
    permissionGroup: permissionGroups.REASON_CODE,
    highlightSideMenuLinkId: 'reason-codes',
    title: 'Reason Codes',
    path: '/settings/reason-codes/',
    breadcrumbs: ['settings'],
    Component: lazy(() => import(
      /* webpackChunkName: "[ReasonCodes]" */
      '../reason-codes/ReasonCodes'
    )),
  },

  // locations
  {
    routeId: 'locations',
    permissionGroup: permissionGroups.LOCATION,
    highlightSideMenuLinkId: 'locations',
    title: 'Shipping Locations',
    description: 'Manage the locations from which you ship or receive goods.',
    breadcrumbs: ['settings'],
    path: '/settings/locations',
    Component: lazy(() => import(
      /* webpackChunkName: "[LocationList]" */
      '../locations/LocationsList'
    )),
  },
  {
    routeId: 'location-create',
    permissionGroup: permissionGroups.LOCATION,
    highlightSideMenuLinkId: 'locations',
    gradientBackground: true,
    title: 'Location',
    path: '/settings/locations/:actionType(create)',
    breadcrumbs: ['settings', 'locations'],
    Component: lazy(() => import(
      /* webpackChunkName: "[Location]" */
      '../locations/Location'
    )),
  },
  {
    routeId: 'location-edit',
    permissionGroup: permissionGroups.LOCATION,
    highlightSideMenuLinkId: 'locations',
    gradientBackground: true,
    title: 'Location',
    path: '/settings/locations/:actionType(edit)/:locationId',
    breadcrumbs: ['settings', 'locations'],
    Component: lazy(() => import(
      /* webpackChunkName: "[Location]" */
      '../locations/Location'
    )),
  },

  // webhook-settings
  {
    routeId: 'webhook-settings',
    permissionGroup: permissionGroups.WEBHOOK,
    highlightSideMenuLinkId: 'webhook-settings',
    title: 'Webhooks',
    breadcrumbs: ['settings'],
    description: `Webhooks allow integrating data into other systems. Each webhook is capable 
          of containing multiple triggers and conditions. Setup webhooks when you need to inform 
          other systems of shipment status updates, label generation or other supported event types.`,
    path: '/settings/webhooks',
    Component: lazy(() => import(
      /* webpackChunkName: "[WebhookList]" */
      '../webhook-settings/WebhookList'
    )),
  },
  {
    routeId: 'webhook-setting-create',
    permissionGroup: permissionGroups.WEBHOOK,
    highlightSideMenuLinkId: 'webhook-settings',
    breadcrumbs: ['settings', 'webhook-settings'],
    title: 'Webhook',
    path: '/settings/webhooks/:actionType(create)',
    Component: lazy(() => import(
      /* webpackChunkName: "[Webhook]" */
      '../webhook-settings/Webhook'
    )),
  },
  {
    routeId: 'webhook-setting-edit',
    permissionGroup: permissionGroups.WEBHOOK,
    highlightSideMenuLinkId: 'webhook-settings',
    breadcrumbs: ['settings', 'webhook-settings'],
    title: 'Webhook',
    path: '/settings/webhooks/:actionType(edit)/:configId',
    Component: lazy(() => import(
      /* webpackChunkName: "[Webhook]" */
      '../webhook-settings/Webhook'
    )),
  },
  {
    routeId: 'api-access',
    permissionGroup: permissionGroups.API_CLIENT,
    highlightSideMenuLinkId: 'api-access',
    title: 'API Access',
    breadcrumbs: ['settings'],
    path: '/settings/api-access/:activeTab?',
    Component: lazy(() => import(
      /* webpackChunkName: "[ApiAccess]" */
      '../api-access/ApiAccess'
    )),
  },
  {
    /* Breadcrumb route */
    routeId: 'api-access-clients',
    permissionGroup: permissionGroups.API_CLIENT,
    highlightSideMenuLinkId: 'api-access',
    title: 'API Access',
    path: '/settings/api-access/clients',
    breadcrumbs: ['settings', 'api-access'],
    Component: lazy(() => import(
      /* webpackChunkName: "[ApiAccess]" */
      '../api-access/ApiAccess'
    )),
  },
  {
    routeId: 'create-client-app-settings',
    permissionGroup: permissionGroups.API_CLIENT,
    highlightSideMenuLinkId: 'api-access',
    title: 'Create Client Application',
    path: '/settings/api-access/clients/create',
    breadcrumbs: ['settings', 'api-access-clients'],
    Component: lazy(() => import(
      /* webpackChunkName: "[ApiAccess]" */
      '../api-access/ClientApp'
    )),
  },
  {
    routeId: 'edit-client-app-settings',
    permissionGroup: permissionGroups.API_CLIENT,
    highlightSideMenuLinkId: 'api-access',
    title: 'Client Application',
    path: '/settings/api-access/clients/edit/:clientId',
    breadcrumbs: ['settings', 'api-access-clients'],
    Component: lazy(() => import(
      /* webpackChunkName: "[ApiAccess]" */
      '../api-access/ClientApp'
    )),
  },
  {
    routeId: 'settings',
    permissionGroup: permissionGroups.SETTINGS,
    highlightSideMenuLinkId: 'settings',
    title: 'Account Settings',
    description: 'Manage your account settings and system configuration.',
    path: '/settings',
    Component: lazy(() => import(
      /* webpackChunkName: "[Settings]" */
      '../settings/Settings'
    )),
  },

  {
    routeId: 'account',
    permissionGroup: permissionGroups.ACCOUNT_SETTING,
    highlightSideMenuLinkId: 'account',
    title: 'Account',
    breadcrumbs: ['settings'],
    description: 'Manage your account settings.',
    path: '/settings/:activeTab(system|merchants|documents|sso|subscription|alerts|super)?',
    Component: lazy(() => import(
      /* webpackChunkName: "[AccountSettings]" */
      '../account/AccountSettings'
    )),
  },
  {
    /*
           * This route is used as breadcrumb,
           * but is otherwise overridden by { routeId: 'account' }
           */
    routeId: 'account-merchants',
    permissionGroup: permissionGroups.ACCOUNT_SETTING,
    highlightSideMenuLinkId: 'account',
    title: 'Merchants',
    path: '/settings/merchants',
    breadcrumbs: ['settings'],
    Component: lazy(() => import(
      /* webpackChunkName: "[AccountSettings]" */
      '../account/AccountSettings'
    )),
  },
  {
    routeId: 'create-merchant-settings',
    permissionGroup: permissionGroups.ACCOUNT_SETTING,
    highlightSideMenuLinkId: 'account',
    title: 'Merchant Settings',
    path: '/settings/merchants/:action(create)',
    breadcrumbs: ['settings', 'account-merchants'],
    Component: lazy(() => import(
      /* webpackChunkName: "[EditMerchant]" */
      '../account/EditMerchant'
    )),
  },
  {
    routeId: 'edit-merchant-settings',
    permissionGroup: permissionGroups.ACCOUNT_SETTING,
    highlightSideMenuLinkId: 'account',
    title: 'Merchant Settings',
    description: 'Manage your merchant settings.',
    path: '/settings/merchants/:action(edit)/:merchant',
    breadcrumbs: ['settings', 'account-merchants'],
    Component: lazy(() => import(
      /* webpackChunkName: "[EditMerchant]" */
      '../account/EditMerchant'
    )),
  },
  {
    routeId: 'connectors',
    permissionGroup: permissionGroups.CONNECTOR,
    highlightSideMenuLinkId: 'connectors',
    title: 'Connectors',
    path: '/settings/connectors',
    breadcrumbs: ['settings'],
    Component: lazy(() => import(
      /* webpackChunkName: "[ConnectorShopify]" */
      '../connectors/Connectors'
    )),
  },
  {
    routeId: 'connector-create',
    permissionGroup: permissionGroups.CONNECTOR,
    highlightSideMenuLinkId: 'connectors',
    title: 'Create Connector',
    path: '/settings/connectors/:connectorType(klaviyo|magento|peoplevox|shopify|shopifyapp|woocommerce)/:action(create)',
    breadcrumbs: ['settings', 'connectors'],
    Component: lazy(() => import(
      /* webpackChunkName: "[Connector]" */
      '../connectors/Connector'
    )),
  },
  {
    routeId: 'connector-edit',
    permissionGroup: permissionGroups.CONNECTOR,
    highlightSideMenuLinkId: 'connectors',
    title: 'Edit Connector',
    path: '/settings/connectors/:connectorType(klaviyo|magento|peoplevox|shopify|shopifyapp|woocommerce)/:action(clone|edit)/:connectorId',
    breadcrumbs: ['settings', 'connectors'],
    Component: lazy(() => import(
      /* webpackChunkName: "[Connector]" */
      '../connectors/Connector'
    )),
  },
  {
    routeId: 'integration-monitor',
    permissionGroup: permissionGroups.INTEGRATION_MONITOR,
    highlightSideMenuLinkId: 'integration-monitor',
    title: 'Integration Monitor',
    breadcrumbs: ['settings'],
    path: '/settings/integration-monitor/:integrationType(api|webhook|connector)?',
    description: 'Monitor your inbound and outbound integrations to identify and troubleshoot issues.',
    Component: lazy(() => import(
      /* webpackChunkName: "[IntegrationMonitor]" */
      '../integration-monitor/IntegrationMonitor'
    )),
  },
  {
    routeId: 'traffic',
    permissionGroup: permissionGroups.MONITORING_DASHBOARD,
    highlightSideMenuLinkId: 'traffic',
    title: 'Traffic',
    breadcrumbs: [],
    path: '/monitor/traffic',
    description: 'Monitor service traffic across all tenants.',
    superAdminReport: true,
    Component: lazy(() => import(
      /* webpackChunkName: "[Traffic]" */
      '../monitoring-dashboard/Traffic'
    )),
  },
  {
    routeId: 'webhook-report',
    permissionGroup: permissionGroups.MONITORING_DASHBOARD,
    highlightSideMenuLinkId: 'webhook-report',
    title: 'Webhook Failures',
    breadcrumbs: [],
    path: '/monitor/webhook-report',
    description: 'Webhook Failure Report',
    superAdminReport: true,
    Component: lazy(() => import(
      /* webpackChunkName: "[WebhookReport]" */
      '../monitoring-dashboard/WebhookReport'
    )),
  },
  {
    routeId: 'carrier-connector-report',
    permissionGroup: permissionGroups.MONITORING_DASHBOARD,
    highlightSideMenuLinkId: 'carrier-connector-report',
    title: 'Carrier Report',
    breadcrumbs: [],
    path: '/monitor/carrier-connector-report/:activeTab(general|api|callbacks|missing-callbacks)?',
    superAdminReport: true,
    fullPageView: true,
    Component: lazy(() => import(
      /* webpackChunkName: "[CarrierConnectorReport]" */
      '../monitoring-dashboard/carrier-connector/CarrierConnectorReport'
    )),
  },
  {
    routeId: 'carrier-detailed-report',
    permissionGroup: permissionGroups.MONITORING_DASHBOARD,
    highlightSideMenuLinkId: 'carrier-connector-report',
    title: 'Carrier Detailed Report',
    breadcrumbs: ['carrier-connector-report'],
    superAdminReport: true,
    path: '/monitor/carrier-detailed-report/:identifier',
    fullPageView: true,
    Component: lazy(() => import(
      /* webpackChunkName: "[CarrierDetailedReport]" */
      '../monitoring-dashboard/carrier-connector/CarrierDetailedReport'
    )),
  },
  {
    routeId: 'carrier-account-detailed-report',
    permissionGroup: permissionGroups.MONITORING_DASHBOARD,
    highlightSideMenuLinkId: 'carrier-connector-report',
    title: 'Carrier Account Detailed Report',
    breadcrumbs: ['carrier-connector-report'],
    superAdminReport: true,
    path: '/monitor/carrier-account-detailed-report/:identifier',
    fullPageView: true,
    Component: lazy(() => import(
      /* webpackChunkName: "[CarrierAccountDetailedReport]" */
      '../monitoring-dashboard/carrier-connector/CarrierAccountDetailedReport'
    )),
  },
  {
    routeId: 'ecomm-integration-report',
    permissionGroup: permissionGroups.MONITORING_DASHBOARD,
    highlightSideMenuLinkId: 'ecomm-integration-report',
    title: 'Ecomm Integration',
    breadcrumbs: [],
    path: '/monitor/ecomm-integration-report',
    description: 'Monitor E-Commerce integration across all tenants.',
    superAdminReport: true,
    Component: lazy(() => import(
      /* webpackChunkName: "[EcommIntegration]" */
      '../monitoring-dashboard/EcommIntegration'
    )),
  }, {
    routeId: 'performance-monitor',
    permissionGroup: permissionGroups.MONITORING_DASHBOARD,
    highlightSideMenuLinkId: 'performance-monitor',
    title: 'Performance Monitoring',
    breadcrumbs: [],
    superAdminReport: true,
    path: '/monitor/performance-monitor/:activeTab(service|flow)?/:subTab?',
    fullPageView: true,
    Component: lazy(() => import(
      /* webpackChunkName: "[PerformanceMonitor]" */
      '../monitoring-dashboard/performance-monitoring/PerformanceMonitor'
    )),
  },
  {
    routeId: 'alerts',
    permissionGroup: permissionGroups.ALERT,
    highlightSideMenuLinkId: 'alerts',
    title: 'Alerts Dashboard',
    description: 'Review and manage alerts generated by Carriyo.',
    breadcrumbs: [],
    path: '/alerts',
    Component: lazy(() => import(
      /* webpackChunkName: "[Alerts]" */
      '../alerts/Alerts'
    )),
  },
];

const noPageRoute = {
  routeId: 'page-not-found',
  permissionGroup: permissionGroups.NONE,
  title: 'Page Not Found',
  path: '*',
  Component: PageNotFound,
};

const nonTenantRoutes = [...routes, noPageRoute];

const tenantRoutes = nonTenantRoutes.map((route) => ({
  ...route,
  routeId: `tenant-${route.routeId}`,
  path: `/tenants/:tenantId${route.path !== '/' ? route.path : ''}`,
}));


const allRoutes = [
  ...routes,
  ...tenantRoutes,
  noPageRoute,
];

if (import.meta.env.DEV) {
  const allRouteIds = map(allRoutes, 'routeId');
  if (uniq(allRouteIds).length !== allRouteIds.length) {
    const diff = chain(allRouteIds)
      .fn(groupBy)
      .fn(pickBy, (group) => group.length > 1)
      .fn(Object.keys)
      .value;
    throw new Error(`You have created duplicate routeId in routes.jsx: ${diff.join(',')}`);
  }

  const routeWithoutPermissionGroup = allRoutes.find(({ permissionGroup }) => !permissionGroup);
  if (routeWithoutPermissionGroup) {
    throw new Error(`route id '${routeWithoutPermissionGroup.routeId}' does not have a permission group`);
  }
}

export default allRoutes;
